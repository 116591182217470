module.exports = [{
      plugin: require('../../../.yarn/__virtual__/gatsby-plugin-gatsby-cloud-virtual-4b80a34dbd/4/buildhome/.yarn_cache/gatsby-plugin-gatsby-cloud-npm-4.19.0-2129f5d294-40cdf7dadd.zip/node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../.yarn/__virtual__/gatsby-plugin-material-ui-virtual-19c2263431/4/buildhome/.yarn_cache/gatsby-plugin-material-ui-npm-4.1.0-2938ee1c08-f1d38d2e50.zip/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToEmotionCacheProps":"src/emotion-cache-props"},
    },{
      plugin: require('../../../../../../buildhome/.yarn_cache/gatsby-plugin-web-font-loader-npm-1.0.4-e6329b179e-7cb4df8ed5.zip/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Cabin","Lato","Cabin:bold","Lato:bold","Inter:bold"]}},
    },{
      plugin: require('../../../.yarn/__virtual__/gatsby-plugin-manifest-virtual-4ab7ee306b/4/buildhome/.yarn_cache/gatsby-plugin-manifest-npm-4.19.0-5c73f87aea-bc47920497.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"11ac6296d555b5dccd62098791e0d970"},
    },{
      plugin: require('../../../.yarn/__virtual__/@sentry-gatsby-virtual-529ea5d36d/4/buildhome/.yarn_cache/@sentry-gatsby-npm-6.19.7-98559ac9b4-bf2192507c.zip/node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://403987968d0541b7b65b6ac76038f66a@o925641.ingest.sentry.io/5961720","sampleRate":0,"environment":"staging","release":"aba22cba1","ignoreErrors":["GQL Operation failed: AdminHomeOpsGetValuationV1","Invalid state"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../.yarn/unplugged/gatsby-virtual-25598fae33/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
